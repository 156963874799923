"use client";

import { motion, useScroll, useSpring } from "framer-motion";

export default function ScrollLinkedAnimation() {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.1,
  });
  return <motion.div className="absolute inset-x-0 top-0 h-[3px] origin-left bg-info" style={{ scaleX }} />;
}
